/*
 * Tweaks for layout 'login': see views/layouts/login.html.erb
 *
 * Note that the 'login' layout applies for both logins and signups
 */

.checkbox--flex-center {
  display: flex;
  justify-content: center;
}

.layout-login {
  // Sticky footer styles
  #page {
    margin: 0 auto -51px; // height of .footer-area + border
    padding: 0 0 50px;

    @media (max-width: @screen-desktop) {
      background: none;
    }
  }

  #page {
    .content-header-area {
      border: none;
      .progressbar {
        border-bottom: none;
        padding-bottom: 0;
        padding-top: (@gutter / 2);
        margin-bottom: (@gutter / 2);
      }
    }

    .header-area {
      background: @body-bg;
      text-align: center;

      .header {
        padding: (@gutter*3) 0 @gutter;
      }
      nav.account-menu {
        position: absolute;
        top: @gutter;
        right: @gutter;
      }
    }

    .content-area {
      padding-top: @gutter;
      padding-bottom: @gutter;

      a:not([class]) {
        color: @brand-primary;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      /* 
       * !important is necessary to override 'outline: 0!important;' in layout.less
       * Poor practice and will easily lead to difficult-to-maintain CSS, but
       * acceptable as the 'Sign up' and 'Log in' pages will be reactified in
       * a few months anyway. [Fiona, 2022/08/04]
       */
      a:focus,
      btn:focus,
      input[type='checkbox']:focus,
      input[type='submit']:focus {
        outline: thin dotted @brand-primary !important;
        outline-offset: @gutter / 3;
      }

      .nav-tabs > li > a {
        color: @gray-darker;
      }

      .flashmessage a {
        color: inherit;
        text-decoration: underline;
      }

      .headings {
        color: @gray-darker;
        text-align: center;
        margin: 0 0 @gutter * 2;

        .signifier-font {
          font-family: 'Signifier';
        }

        .weight-400 {
          font-weight: 400;
        }

        h1 {
          font-weight: 500;
          margin: 0 0 (@gutter / 2);
        }

        h2 {
          margin: 0 0 (@gutter / 2);
        }

        h4 {
          margin-top: @gutter / 3;
        }
      }

      .form {
        .actions > * {
          margin-top: @gutter;
          margin-bottom: @gutter;
          border-radius: 4px;
        }

        #forgot_password {
          float: right;
        }

        #signup_submit,
        #recaptcha_submit {
          background: @brand-primary;
          color: @white;
        }

        #signup_google, #signup_apple {
          border: 1px solid @gray-light;
          color: @gray-darker;
          font-weight: bold;
        }
      }
    }

    #powered_by_sharesight {
      text-align: center;
      margin-bottom: (@gutter*1);
      margin-top: (@gutter*3);
      color: @gray;
    }
  }
}

@media (max-width: @screen-phone) {
  .layout-login {
    .nav-tabs > li {
      width: 50%;
      float: left;
    }
  }
}
